import cookie from "js-cookie";
var cookiebar = document.getElementById("cookiebar");
if (cookiebar) {
    if (!cookie.get("cookie_consent")) {
        cookiebar.style.display = "block";
        var btn = document.querySelector("#cookiebar .button");
        var links = document.querySelectorAll("a");
        btn.addEventListener("click", addCookie);
        for (var i = 0; i < links.length; i++)
            links.item(i).addEventListener("click", addCookie);
    }
    else {
        cookiebar.remove();
    }
}
function addCookie() {
    cookie.set("cookie_consent", "1", { path: '/', expires: 365 });
    cookiebar.remove();
}
